import $ from "jquery";
import { isTestMaster, isDdev } from "../../utils/featureFlag";

// enjoy your spaghetti below
// thanks, I hate it

function isDesktopSize() {
  if (document.getElementById("semicon-header")) {
    if ($(window).width() >= 1200) {
      return true;
    }
  } else if ($(window).width() >= 992) {
    return true;
  }

  return false;
}

function closeSubnav() {
  $(".js-toggle-nav").addClass("collapsed");
  $(".js-subnav").removeClass("show");
  $(".js-toggleable-nav").removeClass("show");
  $(".js-navbar-buttons").removeClass("show");
  $(".js-main-slide").removeClass("slide-out");
  $(".js-open-subnav").removeClass("active");
  $(".js-main-nav").removeClass("active");
}

function navMobileBreadcrumb() {
  const mobNavOpen = document.querySelector(".js-toggle-nav");

  if (mobNavOpen.classList.contains("collapsed") || $(window).width() >= 992) {
    return;
  }

  const currentURL = window.location.pathname;
  const naviStart = document.getElementById("toggleNavbar");
  const navLinkMain = document.getElementsByClassName("nav-link-main-js");
  for (const navLinkMainItem of navLinkMain) {
    if (!currentURL.includes(navLinkMainItem.getAttribute("href"))) {
      continue;
    }

    naviStart.classList.add("slide-out");
    const el = navLinkMainItem.nextElementSibling.nextElementSibling;
    if (!el.classList.contains("js-subnav")) {
      continue;
    }

    el.classList.add("show");
    const navLinkMainChild = document.getElementsByClassName(
      "nav-link-main-child-js"
    );

    for (const navLinkMainChildItem of navLinkMainChild) {
      if (!currentURL.includes(navLinkMainChildItem.getAttribute("href"))) {
        continue;
      }

      navLinkMainChildItem.closest(".js-open-subnav").classList.add("active");
      navLinkMainChildItem
        .closest(".js-navbar-slide")
        .classList.add("slide-out");
      const navLinkMainChildChild = document.getElementsByClassName(
        "nav-link-main-child-child-js"
      );
      for (const navLinkMainChildChildItem of navLinkMainChildChild) {
        if (
          !currentURL.includes(navLinkMainChildChildItem.getAttribute("href"))
        ) {
          continue;
        }

        navLinkMainChildChildItem
          .closest(".js-open-subnav")
          .classList.add("active");
        navLinkMainChildChildItem
          .closest(".js-navbar-slide")
          .classList.add("slide-out");
      }
    }
  }
}

$(document).ready(() => {
  /**
   * FEATURE FLAG for HHWEB-1673.
   */
  if (isTestMaster() || isDdev()) return;

  function slideMainNav() {
    $(".js-main-slide").toggleClass("slide-out");
  }

  function slideNavOnMouseEnter() {
    let mouseenterTimeout;
    const timeoutEnter = 150;

    $(".js-main-nav").on("mouseenter", function () {
      if (!isDesktopSize()) {
        return;
      }

      mouseenterTimeout = setTimeout(() => {
        $(this).addClass("open");
        slideMainNav();
      }, timeoutEnter);
    });

    $(".js-main-nav").on("mouseleave", function () {
      if (!isDesktopSize()) {
        return;
      }

      clearTimeout(mouseenterTimeout);
      $(this).removeClass("open");
      slideMainNav();
    });
  }

  $(".nav-link-mobile").on("click", function () {
    const targetName = $(this).closest(".js-main-nav").data("nav-target");
    const targetModal = $(`#${targetName}NavModal`);

    if (targetModal.length === 0) {
      return;
    }

    targetModal.addClass("show");
    slideMainNav();
  });

  // display nav on mouse enter
  $(".js-subnav").on("mouseenter", function () {
    $(this).closest(".js-main-nav").addClass("active");
  });

  // hide nav on mouse leave
  $(".js-subnav").on("mouseleave", function () {
    $(this).closest(".js-main-nav").removeClass("active");
  });

  $(".js-main-nav-return").on("click", function (ev) {
    ev.stopPropagation();
    const target = $(this).data("nav-target");
    $(this)
      .closest(`.js-main-nav[data-nav-target='${target}']`)
      .removeClass("active");
    slideMainNav();
    $(`#${target}NavModal`).toggleClass("show");
  });

  $(".js-nav-return").on("click", function (ev) {
    ev.stopPropagation();
    $(this)
      .parent()
      .closest(".js-navbar-slide.slide-out")
      .removeClass("slide-out");
    $(this).closest(".js-open-subnav").removeClass("active");
  });

  $(".js-open-subnav").on("click", function (ev) {
    $(this).addClass("active");
    $(this).closest(".js-navbar-slide").addClass("slide-out");
    ev.stopPropagation();
    // disable siblings and descendants when activated
    $(this)
      .siblings()
      .each(function () {
        $(this).removeClass("active");
        $(this).find(".js-open-subnav").removeClass("active");
      });
    $(this).find(".js-open-subnav").removeClass("active");
    $(this).closest(".subnav-container").scrollTop(0);
  });

  // Mobile nav
  $(".js-toggle-nav").on("click", function () {
    $(this).toggleClass("collapsed");
    $("body").toggleClass("noscroll");
    $(".js-toggleable-nav").toggleClass("show");
    $(".js-navbar-buttons").toggleClass("show");
    // Reset slide state of main navbar slide
    $(".js-main-slide").removeClass("slide-out");
    // Hide modal of currently active nav item
    $(".js-subnav").removeClass("show");
    navMobileBreadcrumb();
  });

  // Slide out search input
  const navbarSearchInput = document.querySelector(".js-navbar-search-input");
  if (navbarSearchInput !== null) {
    const navbarSearchInputField = navbarSearchInput.querySelector("input");
    const navbarSearchForm = document.querySelector(
      ".navbar__search-input__form"
    );

    navbarSearchForm.addEventListener("submit", (event) => {
      if (!navbarSearchInput.classList.contains("show")) {
        event.preventDefault();
        navbarSearchInputField.focus();
        navbarSearchInput.classList.add("show");
      }
    });
  }

  // Shrink and change background color of nav on scroll
  if (isDesktopSize()) {
    slideNavOnMouseEnter();
  }

  $(window).on("resize", () => {
    if (isDesktopSize()) {
      slideNavOnMouseEnter();
    }
  });
});

$(".js-close-subnav").click(() => {
  closeSubnav();
});

$(".js-subnav-close").on("click", () => {
  $(".js-main-nav").trigger("mouseout");
});
