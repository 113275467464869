import { onDomReady } from "../../utils/on-dom-ready";
import { isTestMaster, isDdev } from "../../utils/featureFlag";
import "./change-navbar-background-for-scroll-position";
import "./attach-computed-header-height";
import Navigation from "./Navigation";
import SearchBar from "./SearchBar";

import "./nav";

if (isTestMaster() || isDdev()) {
  onDomReady(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navigation = new Navigation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const searchBar = new SearchBar();

    searchBar.onShow(() => {
      navigation.hide();
    });
    navigation.onShow(() => {
      searchBar.hide();
    });
  });
}
